import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { preEventPath, customListComponentPath } from 'bv-helpers/routes';
import { Link } from 'bv-components';

const CardFooter = ({ customListComponentInfo, ...props }) => (
  <div className="market-view-card-footer">
    <Link
      to={
        customListComponentInfo.navigateToCustomListComponent
          ? customListComponentPath({ customListComponentInfo, ...props })
          : preEventPath(props)
      }
      className="bvs-button is-primary"
    >
      {t('see_more')}
    </Link>
  </div>
);

CardFooter.propTypes = {
  customListComponentInfo: PropTypes.instanceOf(Object).isRequired,
};

export default CardFooter;
