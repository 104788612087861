import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeGetEventOutcomes } from 'sportsbook-markets-selector';
import OutcomesHeaderContainer from './outcomes_header_container';

const OutcomesHeaderRedux = ({ eventId }) => {
  const getEventOutcomes = useMemo(makeGetEventOutcomes, []);
  const outcomes = useSelector((state) => getEventOutcomes(state, { eventId }));

  return <OutcomesHeaderContainer outcomes={outcomes} />;
};

OutcomesHeaderRedux.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default OutcomesHeaderRedux;
