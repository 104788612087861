import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import BetBoostCard from './bet_boost_card';

const Layout = ({ events }) => (
  <Carousel scrollStep={284} largeArrows>
    {events.map((event) => (
      <BetBoostCard event={event} />
    ))}
  </Carousel>
);

Layout.propTypes = {
  events: PropTypes.instanceOf(Array).isRequired,
};

export default Layout;
