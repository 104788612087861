import { useRef } from 'react';
import PropTypes from 'prop-types';

import { ConfigContext } from 'SportsbookOverview/contexts';
import { Card } from 'bv-components';
import Markets from './markets';
import CardHeader from './card_header';
import CardFooter from './card_footer';

const BetBoostCard = ({
  event: {
    sportEventPathId, meetingId, id, d, m, customListComponentInfo,
  },
}) => {
  const { current: contextValue } = useRef({ prevPrice: true });

  return (
    <Card className="market-view-body-wrapper">
      <ConfigContext.Provider value={contextValue}>
        <CardHeader description={d} eventId={id} />
        <Markets marketIds={m} />
        <CardFooter
          eventId={id}
          sportEventPathId={sportEventPathId}
          meetingId={meetingId}
          customListComponentInfo={customListComponentInfo}
        />
      </ConfigContext.Provider>
    </Card>
  );
};

BetBoostCard.propTypes = {
  event: PropTypes.shape({
    sportEventPathId: PropTypes.number.isRequired,
    meetingId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    d: PropTypes.string.isRequired,
    m: PropTypes.instanceOf(Array).isRequired,
    customListComponentInfo: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
};

export default BetBoostCard;
