import PropTypes from 'prop-types';
import OutcomesHeader from 'SportsbookCommon/bet_boost/outcomes_header';

const CardHeader = ({ description, eventId }) => (
  <>
    <h3 className="bvs-h4 bvs-ellipsis">{ description }</h3>
    <OutcomesHeader eventId={eventId} />
  </>
);

CardHeader.propTypes = {
  description: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
};

export default CardHeader;
