import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFetch } from 'bv-hooks';
import { handleMarketsAndOutcomes, withCancellation } from 'sportsbook-services';

import { Skeleton } from 'bv-components';

import fetchBetBoosts from './api/fetch_bet_boosts';
import Header from './components/header';
import Layout from './components/layout';

const App = ({ componentId, title }) => {
  const dispatch = useDispatch();

  const cancelableFetchBetBoosts = withCancellation((cancelled = { current: false }) => (
    fetchBetBoosts(componentId)
      .then(({ events, markets, outcomes }) => {
        if (cancelled.current) return undefined;

        handleMarketsAndOutcomes(markets, outcomes, [], [], dispatch);

        return {
          events,
          marketsIds: Object.keys(markets),
          outcomesIds: Object.keys(outcomes),
        };
      })
  ));

  const [{ events = [], marketsIds = [], outcomesIds = [] } = {}, loading] = useFetch(
    cancelableFetchBetBoosts.execute,
    [componentId],
  );

  useEffect(() => () => {
    cancelableFetchBetBoosts.cancel();
    handleMarketsAndOutcomes({}, {}, marketsIds, outcomesIds, dispatch);
  }, [componentId]);

  if (loading) return <Skeleton skeletonType="bet-boost-market with-carousel" />;

  if (!events.length || !outcomesIds.length) return null;

  return (
    <div className="bet-boost-market with-carousel">
      <Header title={title} />
      <Layout events={events} />
    </div>
  );
};

App.propTypes = {
  componentId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default App;
