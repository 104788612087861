import PropTypes from 'prop-types';
import Outcome from './outcome';

const OucomesView = ({ outcomes }) => (
  <div className="market-view-body">
    <div className="market-view-body-group">
      {
        // This BetBoost component we're displaying to the customer is built
        // of outcomes from several markets, because of this, we need to provide to
        // every outcome he status of its market so if the market is suspended
        // we display the outcome as suspended on the UI
        outcomes.map(({ id, marketStatus }) => (
          <Outcome
            id={id}
            marketStatus={marketStatus}
            key={id}
          />
        ))
      }
    </div>
  </div>
);

OucomesView.propTypes = {
  outcomes: PropTypes.instanceOf(Array).isRequired,
};

export default OucomesView;
