import PropTypes from 'prop-types';
import classnames from 'classnames';

import BetBuilderLegs from './bet_builder_legs';

const outcomeButtonClassName = (selected, suspended) => classnames(
  'outcome bvs-button-sport is-square outcome-button bet-boost-button',
  {
    active: selected,
    disabled: suspended,
  },
);

const OutcomeView = ({
  desc,
  legDescriptions,
  price,
  prevPrice,
  onClick,
  selected,
  suspended,
}) => {
  const isBetBuilder2 = legDescriptions.length > 1;

  return (
    <div className="market-view-wrapper">
      {
        isBetBuilder2 ? (
          <BetBuilderLegs legDescriptions={legDescriptions} />
        ) : <div className="market-view-outcome-description">{desc}</div>
      }
      {
        prevPrice && (
          <button type="button" className="bvs-button-sport is-square previous-price-button outcome-button disabled">
            <div className="outcome-n-wrapper bet"><span className="outcome-n-price price">{prevPrice}</span></div>
          </button>
        )
      }
      <button type="button" className={outcomeButtonClassName(selected, suspended)} onClick={onClick}>
        <div className="outcome-n-wrapper bet"><span className="outcome-n-price price">{price}</span></div>
      </button>
    </div>
  );
};

OutcomeView.propTypes = {
  desc: PropTypes.string.isRequired,
  legDescriptions: PropTypes.arrayOf(PropTypes.string),
  price: PropTypes.string.isRequired,
  prevPrice: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  suspended: PropTypes.bool.isRequired,
};

OutcomeView.defaultProps = {
  prevPrice: null,
  legDescriptions: [],
};

export default OutcomeView;
