import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const OutcomesHeaderView = ({ prevPrice }) => (
  <div className="outcomes-column-title-wrapper">
    {
      prevPrice && (
        <div className="outcomes-column-title">{t('javascript.was')}</div>
      )
    }
    <div className="outcomes-column-title boost">{t('javascript.boost')}</div>
  </div>
);

OutcomesHeaderView.propTypes = {
  prevPrice: PropTypes.bool,
};

OutcomesHeaderView.defaultProps = {
  prevPrice: null,
};

export default OutcomesHeaderView;
