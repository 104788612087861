import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'SportsbookOverview/contexts';
import withOutcome from 'sportsbook-with-outcome';
import OutcomeView from './outcome_view';

const OutcomeContainer = ({
  id,
  marketStatus,
  toggleBetslip,
  selected,
  outcome: {
    desc,
    legDescriptions,
    h,
    formattedPrice,
    formattedWasPrice,
  },
}) => {
  const { prevPrice } = useContext(ConfigContext);
  // Don't render the Outcome if hidden
  if (h) return null;

  return (
    <OutcomeView
      id={id}
      desc={desc}
      legDescriptions={legDescriptions}
      price={formattedPrice}
      prevPrice={prevPrice && formattedWasPrice}
      suspended={marketStatus === 2}
      onClick={toggleBetslip}
      selected={selected}
    />
  );
};

OutcomeContainer.propTypes = {
  id: PropTypes.number.isRequired,
  marketStatus: PropTypes.number.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
};

export default withOutcome(OutcomeContainer);
