import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { flatten, isEqual } from 'underscore';

import OutcomesView from 'SportsbookCommon/bet_boost/outcomes';

const Markets = ({ marketIds }) => {
  const markets = useSelector((state) => state.markets, isEqual);
  const outcomes = flatten(marketIds.map((m) => markets[m].o.map((id) => ({
    id,
    marketStatus: markets[m].st,
  }))));

  return <OutcomesView outcomes={outcomes} />;
};

Markets.propTypes = {
  marketIds: PropTypes.arrayOf(Number).isRequired,
};

export default Markets;
