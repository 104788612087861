import PropTypes from 'prop-types';

const BetBuilderLegs = ({ legDescriptions }) => (
  <div className="betbuilder-outcome-legs">
    {legDescriptions.map((legDesc) => (
      <span className="betbuilder-outcome-leg">{legDesc}</span>
    ))}
  </div>
);

BetBuilderLegs.propTypes = {
  legDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BetBuilderLegs;
