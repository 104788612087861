import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Icon } from 'bv-components';

const Header = ({ title }) => (
  <h2 className="bvs-header bvs-ellipsis">
    <Icon className="bvs-event-icon is-bet-boost" big />
    { title || t('bet_boost') }
  </h2>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
