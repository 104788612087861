import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from 'SportsbookOverview/contexts';
import OutcomesHeaderView from './outcomes_header_view';

const OutcomesHeaderContainer = ({ outcomes }) => {
  const { prevPrice } = useContext(ConfigContext);
  const hasWasPrice = outcomes.some((o) => o.formattedWasPrice && !o.h);

  return (
    <OutcomesHeaderView
      prevPrice={prevPrice && hasWasPrice}
    />
  );
};

OutcomesHeaderContainer.propTypes = {
  outcomes: PropTypes.arrayOf(Object).isRequired,
};

export default OutcomesHeaderContainer;
