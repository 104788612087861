import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

import presentBetBoosts from '../presenters/present_bet_boosts';

const marketsOutcomes = (componentId) => (
  getJSON(`/sportsbook_components/home_components/components/${componentId}`, { c: locale() })
    .catch(() => {})
    .then(presentBetBoosts)
);

export default marketsOutcomes;
